































































































































































































































































































































































import Vue from "vue";
import apiClient from "@/services/apiClient";
import userManager from "@/services/userManager";
import util from "@/services/util";
import {VueEditor} from "vue2-editor";

export default Vue.extend({
    components: {
        VueEditor,
    },
    data() {
        return {
            util,
            userManager,
            dialog: false,
            dialogType: false,
            formType: false,
            hideCreate: false,
            loadingCreate: false,
            snackbar: false,
            fails: "",
            name: "",
            form: false,
            dialogDelete: false,
            ruleRequired: (value) => !!value || this.$t("REQUIRED"),
            ruleRequiredArray: (value) =>
                !!(Array.isArray(value) && value.length > 0) || this.$t("REQUIRED"),
            headers: [
                {text: "ID quy trình", value: "id"},
                {text: "loại quy trình", value: "type"},
                {text: "Người tạo", value: "created_by"},
                {text: "Đối tượng", value: "object"},
                {text: "Tiêu đề", value: "title"},
                {text: "Ngày tạo quy trình", value: "created_at"},
                {text: this.$t("ACTIONS"), value: "actions", sortable: false},
            ],
            options: {},
            search: {
                object: "",
                title: "",
                created_by: "",
                id: "",
            },
            loading: false,
            data: [],
            dataCount: 0,
            editedIndex: -1,
            editedItem: {},
            allowDelete: {},
            users: [],
            types: [],
            usersByRoles: [],
            userInfo: userManager.getUserInfo(),
            availableRoleMap: {
                k: this.$t("ROLE_K"),
                xnk: this.$t("ROLE_XNK"),
                kd: this.$t("ROLE_KD"),
                t: this.$t("ROLE_T"),
                kt: this.$t("ROLE_KT"),
                td: this.$t("ROLE_TD"),
                gd: this.$t("ROLE_GD"),
                htkd: this.$t("ROLE_HTKD"),
                cs: "Chính sách",
                kvn: "Kho VN",
                ktt: "Kế toán trưởng",
                mkt: "Marketing",
            },
        };
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "Tạo quy trình" : this.$t("EDIT");
        },
        availableProgresses() {
            const items = [];
            for (const k in this.availableProgressMap) {
                items.push({
                    text: this.availableProgressMap[k],
                    value: k,
                });
            }
            return items;
        },
        availableRoles() {
            const items = [];
            for (const k in this.availableRoleMap) {
                items.push({
                    text: this.availableRoleMap[k],
                    value: k,
                });
            }
            return items;
        },
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
        options: {
            handler() {
                this.initialize();
            },
            deep: true,
        },
        dialogType: {
            handler(val) {
                if (val === false) {
                    this.hideCreate = false
                    this.name = ""
                }
            },
            deep: true,
        },

    },
    methods: {
        async initialize() {
            const filters = [];
            const search = Object.assign({...this.search}, {
                type_web: "process"
            });
            for (const searchKey in search) {
                if (search[searchKey]) {
                    if (searchKey === "id") {
                        search[searchKey] = search[searchKey].replace("QT", "");
                    }
                    if (searchKey === "title" || searchKey === "object") {
                        filters.push({
                            key: searchKey,
                            operator: "LIKE",
                            value: `%${search[searchKey]}%`,
                        });
                    } else {
                        filters.push({
                            key: searchKey,
                            operator: "=",
                            value: search[searchKey],
                        });
                    }
                }
            }
            this.loading = true;
            const {items, count} = await apiClient.processGuiderGetAll({
                options: this.options,
                filters,
            });
            this.data = items
            this.dataCount = count;
            this.loading = false;
        },
        editItem(item) {
            this.editedIndex = this.data.indexOf(item);
            this.editedItem = Object.assign({}, item);
            if (!Array.isArray(this.editedItem.object)) {
                this.editedItem.object = [
                    this.editedItem.object,
                ];
            } else {
                this.editedItem.object = [...new Set(this.editedItem.object)];
            }
            this.dialog = true;
        },
        deleteItem(item) {
            this.editedIndex = this.data.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        async deleteItemConfirm() {
            this.closeDelete();
            await apiClient.processGuiderDelete(this.editedItem);
            await this.initialize();
        },
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        async save() {
            let result;
            const userInfo = {...this.userManager.getUserInfo()};
            if (userInfo.sub) {
                this.editedItem["created_by"] = userInfo.sub;
            }
            if (
                this.editedItem.object &&
                Array.isArray(this.editedItem.object) &&
                this.editedItem.object.length > 0
            ) {
                this.editedItem.object =
                    this.editedItem.object.filter((id) => id !== "all" && id !== null);
            } else {
                delete this.editedItem.object
            }
            if (this.editedItem) {
                this.editedItem.type_web = "process"
            }
            if (Array.isArray(this.editedItem.roles) && this.editedItem.roles.length === 0) {
                this.editedItem.roles = []
            }
            if (this.editedIndex > -1) {
                result = await apiClient.processGuiderUpdate(this.editedItem);
            } else {
                result = await apiClient.processGuiderCreate(this.editedItem);
            }
            if (!result.errors) {
                this.close();
                await this.initialize();
            } else {
                this.snackbar = true;
                this.fails = result.message;
            }
        },
        getAllUser() {
            this.usersByRoles = [];
            apiClient
                .userGetAll()
                .then((res) => {
                    this.users = [
                        {
                            id: "all",
                            name: "Tất cả",
                        },
                    ].concat(
                        res.items.map((item) => {
                            item.id = String(item.id);
                            return item;
                        })
                    );
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                });
        },
        getName(value, data, keyData = "id", keyName = "name") {
            if (value) {
                if (Array.isArray(data)) {
                    const length = data.length;
                    for (let i = 0; i < length; i++) {
                        if (data[i][keyData] === value) {
                            return data[i][keyName];
                        }
                    }
                }
                return value;
            }
            return "";
        },
        goToDetailsObject(record) {
            console.log(record);
        },
        onChangeObject(val) {
            const isAll = val.includes("all");
            if (isAll) {
                this.editedItem.object = this.users.map((item) => {
                    return item.id;
                });
                this.users.shift();
                this.users.unshift({
                    name: "Bỏ chọn",
                    id: "removeAll",
                });
            }
            if (val.includes("removeAll")) {
                this.editedItem.object = [];
                this.users.shift();
                this.users.unshift({
                    name: "Tất cả",
                    id: "all",
                });
            }
        },
        async processGuiderCodeDelete(id) {
            this.loadingCreate = true
            const x = await apiClient.processGuiderCodeDelete(id)
            if (x) {
                this.getAllGuiderCode()
                this.loadingCreate = false
            }
        },
        getAllGuiderCode() {
            const filters = []
            filters.push({
                key: 'type_web',
                operator: "=",
                value: `process`,
            });

            apiClient
                .processGuiderCodeGetAll({
                    options: {},
                    filters,
                })
                .then((res) => {
                    this.types = res.items.map(item => {
                        return {
                            name: item.name,
                            id: String(item.id)
                        }
                    })
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                });
        },
        async saveCode() {
            this.hideCreate = !this.hideCreate
            this.loadingCreate = true
            const params = {
                type_web: "process",
                name: this.name
            }
            const res = await apiClient.processGuiderCodeCreate(params)
            if (res) {
                this.getAllGuiderCode()
                this.name = ""
                this.loadingCreate = false
            }
        },
        closeType () {
            this.dialogType = false
        }
    },
    created() {
        this.getAllUser();
        this.getAllGuiderCode();
    },
});
